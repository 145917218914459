
import { defineComponent, ref, watch } from "vue";

import { useCart } from "@/use/useCart";
import { formatCurrency } from "@/utils";

import UiAppbarButton from "@/plugins/ui/components/appbar/UiAppbarButton.vue";
import UiIcon from "@/plugins/ui/components/icon/UiIcon.vue";
import UiCounter from "@/plugins/ui/components/counter/UiCounter.vue";
import UiCell from "@/plugins/ui/components/cell/UiCell.vue";
import UiHeadline from "@/plugins/ui/components/typography/UiHeadline.vue";
import UiBtn from "@/plugins/ui/components/button/UiBtn.vue";

import UiModalPage from "./ui/UiModalPage.vue";
import UiModalPageHeader from "./ui/UiModalPageHeader.vue";
import UiModalPageHeaderClose from "./ui/UiModalPageHeaderClose.vue";
import UiIconBtn from "./ui/UiIconBtn.vue";
import UiCaption from "@/plugins/ui/components/typography/UiCaption.vue";
import UiHorizontalScroll from "./ui/UiHorizontalScroll.vue";
import UiTab from "./ui/UiTab.vue";
import { useRequests } from "@/use/useRequests";
import { useAsync } from "@/use/useAsync";
import UiTitle from "@/plugins/ui/components/typography/UiTitle.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    UiAppbarButton,
    UiIcon,
    UiCounter,
    UiModalPage,
    UiModalPageHeader,
    UiModalPageHeaderClose,
    UiCell,
    UiHeadline,
    UiIconBtn,
    UiBtn,
    UiCaption,
    UiHorizontalScroll,
    UiTab,
    UiTitle,
  },

  inheritAttrs: false,

  setup() {
    const router = useRouter();

    const showModal = ref(false);
    const selectedRecess = ref(-1);

    const { cart, sum, size, addToCart, removeFromCart, clearCart } = useCart();
    const { recess, fetchRecess, createRequest } = useRequests();

    const fetchData = useAsync(fetchRecess, false);

    const handleCreateRequest = useAsync(async () => {
      await createRequest({
        "recess-id": selectedRecess.value,
        items: Object.values(cart.value).map(({ id, count }) => ({
          id,
          count,
        })),
      });

      showModal.value = false;
      router.back();
    });

    watch(
      () => showModal.value,
      (value) => value && fetchData.execute()
    );

    return {
      recess,
      selectedRecess,
      formatCurrency,
      showModal,
      cart,
      sum,
      size,
      addToCart,
      clearCart,
      removeFromCart,
      handleCreateRequest,
    };
  },
});
