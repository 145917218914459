
import { defineComponent } from "vue";
import { useTimeoutFn } from "@vueuse/core";

export default defineComponent({
  props: {
    x: { type: Number },
    y: { type: Number },
    size: { type: Number },
  },

  emits: ["clear"],

  setup(props, { emit }) {
    useTimeoutFn(() => emit("clear"), 480);

    return {
      props,
    };
  },
});
