import { ref } from "vue";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";

export function useIdentifiers() {
  const store = useStore();

  const identifiers = ref<Array<Identifier & { services: Service[] }>>([]);
  const services = ref<Service[]>([]);

  async function updateIdentifier(
    identifier: Identifier,
    params: { active: boolean }
  ) {
    const { data } = await Api.updateIdentifier(
      store.currentUser.value!.id,
      identifier.id,
      params
    );

    Object.assign(identifier, data);
  }

  async function fetchIdentifiers() {
    const { data: identifierList } = await Api.fetchIdentifiers(
      store.currentUser.value!.id
    );

    const { data: serviceList } = await Api.fetchServices(
      store.currentUser.value!.id
    );

    identifiers.value = identifierList.map((identifier) => ({
      ...identifier,
      services: serviceList.data.filter(
        (service) => service.target === identifier.id
      ),
    }));

    services.value = serviceList.data.filter(
      (service) =>
        service.label !== "subscriptions" && service.label.startsWith("buy")
    );
  }

  return {
    identifiers,
    services,
    updateIdentifier,
    fetchIdentifiers,
  };
}
