
import "./UiTabbar.css";

import { computed, defineComponent, inject, onMounted, ref } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";
import { Platform } from "@/lib/platform";

import { TabbarHeightKey } from "../../injections";

export default defineComponent({
  setup() {
    const tabbarRef = ref<HTMLDivElement>();
    const height = inject(TabbarHeightKey, ref(0));

    const g = ref();

    const platform = usePlatform();

    const rootClasses = computed(() => [
      getClassName("UiTabbar", platform),
      {},
    ]);

    onMounted(() => {
      height.value =
        platform === Platform.IOS ? tabbarRef.value?.clientHeight || 48 : 56;
    });

    return {
      tabbarRef,
      rootClasses,
    };
  },
});
