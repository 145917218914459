import { ref } from "vue";

import * as Api from "@/api";
import { QueryParams } from "@/api/httpClient";
import { useStore } from "@/use/useStore";
import { IOperation } from "@/types/operation";

export function useOperations() {
  const { currentUser } = useStore();

  const operations = ref<IOperation[]>([]);

  async function fetchOperations(params?: QueryParams) {
    const { data } = await Api.fetchOperations(currentUser.value!.id, params);
    operations.value = data.data;
  }

  return {
    operations,
    fetchOperations,
  };
}
