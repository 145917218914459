import { ref } from "vue";
import { tryOnUnmounted } from "@vueuse/core";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";

export function useTokens() {
  let telegramTokenTimer: number | undefined = undefined;
  let authTokenTimer: number | undefined = undefined;

  tryOnUnmounted(() => {
    clearInterval(telegramTokenTimer);
    clearInterval(authTokenTimer);
  });

  const { currentUser } = useStore();

  const telegramToken = ref<TelegramToken>({ value: "", timeout: 0 });
  const authToken = ref<AuthToken>({ value: "", timeout: 0 });
  const entrantScore = ref<EntrantScore>({
    id: "",
    default_entrant_password: true,
    entrant_password: "",
  });

  async function fetchTelegramToken() {
    clearInterval(telegramTokenTimer);

    const { data } = await Api.fetchBotToken(currentUser.value!.id);

    telegramToken.value = {
      value: data.value,
      timeout: Math.floor(data.timeout / 1000),
    };

    telegramTokenTimer = setInterval(() => {
      telegramToken.value.timeout--;
      telegramToken.value.timeout <= 0 && fetchTelegramToken();
    }, 1000);
  }

  async function fetchAuthToken() {
    clearInterval(authTokenTimer);

    const { data } = await Api.fetchLkToken(currentUser.value!.id);

    authToken.value = {
      value: data.value,
      timeout: Math.floor(data.timeout / 1000),
    };

    authTokenTimer = setInterval(() => {
      authToken.value.timeout--;
      authToken.value.timeout <= 0 && fetchAuthToken();
    }, 1000);
  }

  async function fetchEntrantScore() {
    const { data } = await Api.fetchEntrantScore(currentUser.value!.id);
    entrantScore.value = data;
  }

  return {
    telegramToken,
    fetchTelegramToken,

    authToken,
    fetchAuthToken,

    entrantScore,
    fetchEntrantScore,
  };
}
