import { computed, ref } from "vue";

import * as Api from "@/api";
import { IBan, ILockPosition } from "@/types/ban";
import { useStore } from "@/use/useStore";
import { useDebounceFn } from "@vueuse/core";

export function useBans() {
  const store = useStore();

  const lockPositions = ref<ILockPosition[]>([]);
  const bans = ref<IBan[]>([]);

  const lockedFoods = computed(() =>
    bans.value.filter((ban) => ban.lockType === "food").map((ban) => ban.lockId)
  );
  const lockedTypes = computed(() =>
    bans.value.filter((ban) => ban.lockType === "type").map((ban) => ban.lockId)
  );

  async function fetchLockPositions() {
    const { data } = await Api.fetchLockPositions(store.currentUser.value!.id);
    lockPositions.value = data;
  }

  async function fetchBans() {
    const { data } = await Api.fetchBans(store.currentUser.value!.id);
    bans.value = data;
  }

  function isTypeBanned(typeId: number): boolean {
    return lockedTypes.value.includes(typeId);
  }

  function isFoodBanned(foodId: number, typeId?: number): boolean {
    return typeId && isTypeBanned(typeId)
      ? true
      : lockedFoods.value.includes(foodId);
  }

  const debouncedUpdateBan = useDebounceFn(
    async (payload: IBan[]): Promise<void> => {
      await Api.updateBans(store.currentUser.value!.id, payload);
      bans.value = payload;
    }
  );

  async function addBan(position: IBan): Promise<void> {
    if (bans.value.includes(position)) return;
    bans.value.push(position);
    await debouncedUpdateBan(bans.value);
  }

  async function removeBan(position: IBan): Promise<void> {
    const index = bans.value.findIndex(
      (ban) =>
        ban.lockId === position.lockId && ban.lockType === position.lockType
    );

    index > -1 && bans.value.splice(index, 1);
    await debouncedUpdateBan(bans.value);
  }

  return {
    lockPositions,
    fetchLockPositions,

    bans,
    fetchBans,

    lockedFoods,
    lockedTypes,

    isTypeBanned,
    isFoodBanned,

    addBan,
    removeBan,
  };
}
