
import "./UiSubhead.css";

import { computed, defineComponent, PropType } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";

type SubheadWeight = "regular" | "medium" | "semibold" | "bold";

export default defineComponent({
  inheritAttrs: false,

  props: {
    weight: { type: String as PropType<SubheadWeight>, default: "regular" },
    tag: { type: String, default: "h4" },
  },

  setup(props) {
    const platform = usePlatform();

    const rootClasses = computed(() => [
      getClassName("UiSubhead", platform),
      `UiSubhead--w-${props.weight}`,
      {},
    ]);

    return {
      props,
      rootClasses,
    };
  },
});
