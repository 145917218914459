import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  "aria-hidden": "true",
  class: "UiTappable__waves"
}
const _hoisted_2 = {
  key: 1,
  class: "UiTappable__interactive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiWave = _resolveComponent("UiWave")!

  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.component), _mergeProps(_ctx.$attrs, {
    onClick: _ctx.onClick,
    onTouchstart: _ctx.onStart,
    tabindex: _ctx.isCustomElement && !_ctx.disabled ? 0 : undefined,
    role: _ctx.isCustomElement ? _ctx.role : undefined,
    "aria-disabled": _ctx.isCustomElement ? _ctx.disabled : null,
    disabled: _ctx.disabled,
    class: _ctx.rootClasses,
    ref: "containerRef"
  }), {
    default: _withCtx(() => [
      (_ctx.hasActive)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.waves, (wave) => {
              return (_openBlock(), _createBlock(_component_UiWave, {
                onClear: _ctx.removeWave,
                key: wave.id,
                x: wave.x,
                y: wave.y,
                size: wave.size
              }, null, 8, ["onClear", "x", "y", "size"]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.hasHover || _ctx.hasActive)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["onClick", "onTouchstart", "tabindex", "role", "aria-disabled", "disabled", "class"]))
}