import * as Api from "@/api";
import { TAuthCredential } from "@/types/auth";
import { TUserWithSchool } from "@/types/user";

async function fetchUsers(): Promise<TUserWithSchool[]> {
  try {
    const { data: users } = await Api.fetchUsers();

    const usersWithSchools = await Promise.all(
      users.map(async (account) => {
        try {
          const { data: school } = await Api.fetchSchool(account.id);
          return { ...account, school };
        } catch {
          return { ...account, school: null };
        }
      })
    );

    return usersWithSchools;
  } catch {
    return [];
  }
}

async function touchUser(credential: TAuthCredential): Promise<void> {
  await Api.touchUser(credential);
  await Api.fetchUsers();
}

async function detachUser(id: number): Promise<void> {
  await Api.detachUser(id);
  await Api.fetchUsers();
}

export default {
  fetchUsers,
  touchUser,
  detachUser,
};
