import { computed } from "vue";

import * as Api from "@/api";
import { useStore, useGlobalStore } from "@/use/useStore";
import { usePermissions } from "@/use/usePermissions";
import { useUsers } from "@/use/useUsers";

export function useAuth() {
  const store = useStore();
  const globalStore = useGlobalStore();

  const { getUsers } = useUsers();

  const { definePermission } = usePermissions();

  const authUser = computed(() => store.authUser);
  const existSession = computed(() => globalStore.value.user);
  const savedUserCredential = computed(() => globalStore.value.credential);

  async function fetchCurrentUser() {
    const { data: user } = await Api.fetchCurrentUser();
    // FIXME optimize multiple promise call
    const { data: school } = await Api.fetchSchool(user.id);
    const { data: permissions } = await Api.fetchPermissions(user.id);

    await getUsers();

    store.setCurrentUser({ ...user, school });
    store.setAuthUser({ ...user, school });

    definePermission(permissions);
  }

  async function login(credential: UserCredential) {
    const { data: user } = await Api.login(credential);
    // FIXME optimize multiple promise call
    const { data: school } = await Api.fetchSchool(user.id);
    const { data: permissions } = await Api.fetchPermissions(user.id);

    await getUsers();

    store.setCurrentUser({ ...user, school });
    store.setAuthUser({ ...user, school });

    definePermission(permissions);

    globalStore.value.user = { ...user, school };
    globalStore.value.credential = { ...credential };
  }

  async function logout() {
    await Api.logout();
    store.setCurrentUser(null);
    store.setAuthUser(null);
  }

  async function changePassword(id: number, passwords: UpdatePasswordData) {
    await Api.changePassword(id, passwords);
    store.setCurrentUser({
      ...store.currentUser.value!,
      default_password: false,
    });
  }

  return {
    authUser,
    existSession,
    savedUserCredential,

    fetchCurrentUser,
    login,
    logout,
    changePassword,
  };
}
