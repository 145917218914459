import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "UiHeader__main" }
const _hoisted_2 = {
  key: 0,
  class: "UiHeader__right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), _mergeProps(_ctx.$attrs, { class: _ctx.rootClasses }), {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          (_ctx.title)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.HeaderContent.component), _mergeProps({ key: 0 }, _ctx.HeaderContent.props, { class: "UiHeader__content" }), {
                default: _withCtx(() => [
                  _createElementVNode("span", {
                    class: _normalizeClass(["UiHeader__content-in", {
              'UiHeader__content-in--multiline': _ctx.multiline,
            }])
                  }, _toDisplayString(_ctx.title), 3)
                ]),
                _: 1
              }, 16))
            : _createCommentVNode("", true),
          (_ctx.description)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.SubtitleContent.component), _mergeProps({ key: 1 }, _ctx.SubtitleContent.props, { class: "UiHeader__subtitle" }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.description), 1)
                ]),
                _: 1
              }, 16))
            : _createCommentVNode("", true)
        ])
      ]),
      (_ctx.$slots.right)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "right")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["class"]))
}