
import { computed, defineComponent, PropType, ref } from "vue";
import { InputRule } from "@/components/ui/types";

export default defineComponent({
  props: {
    label: String,
    type: String,
    readonly: Boolean,
    focused: Boolean,
    disabled: Boolean,
    rules: Array as PropType<Array<InputRule>>,
    modelValue: [String, Number],
  },

  emits: ["update:modelValue", "focus", "blur"],

  expose: ["error"],

  setup(props, { emit }) {
    const pristine = ref(true);
    const focus = ref(false);

    const error = computed(() => {
      let error = null;

      for (const check of props.rules || []) {
        if (error) {
          break;
        } else {
          const result = check(props.modelValue);
          error = typeof result === "string" ? result : null;
        }
      }

      return error;
    });

    const handleInput = (event: Event) => {
      pristine.value = false;
      emit("update:modelValue", (event.target as HTMLInputElement).value);
    };

    const onFocus = (e: Event) => {
      focus.value = true;
      emit("focus", e);
    };

    const onBlur = (e: Event) => {
      focus.value = false;
      emit("blur", e);
    };

    return {
      props,
      focus,
      error,
      pristine,
      onFocus,
      onBlur,
      handleInput,
    };
  },
});
