<script setup lang="ts"></script>

<template>
  <router-link
    v-bind="$attrs"
    to="/"
    class="app-logo flex items-center overflow-hidden"
  >
    <img src="@/assets/images/logo.svg" />

    <div class="ml-6">
      <p class="text-xl font-bold leading-none">я.пионер</p>
      <p class="text-xs text-gray-400">личный кабинет</p>
    </div>
  </router-link>
</template>
