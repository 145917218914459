import { ref } from "vue";

import { TPermission } from "@/types/permission";

// FIXME move to TPermission type
export interface ITempPermission {
  name: TPermission;
  type: "subscription" | "unsupported" | "supported"; // unsupported - Нет установленного модуля в столовой;
  available: boolean;
  priority?: number; // FIXME uncheck temporal
}

const permissions = ref<ITempPermission[]>([]);

export function usePermissions() {
  async function definePermission(payload: ITempPermission[]) {
    permissions.value = payload;
  }

  function isAllow(name: TPermission) {
    return !!permissions.value.find((p) => p.name === name)?.available;
  }

  function getDenyType(name: TPermission) {
    return permissions.value.find((p) => p.name === name)?.type;
  }

  return {
    permissions,
    isAllow,
    getDenyType,
    definePermission,
  };
}
