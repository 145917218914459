
import "./UiCell.css";

import { computed, defineComponent } from "vue";

import UiTappable from "../tappable/UiTappable.vue";
import UiIcon from "../icon/UiIcon.vue";

export default defineComponent({
  components: { UiTappable, UiIcon },

  inheritAttrs: false,

  props: {
    title: { type: [Number, String] },
    description: { type: String },
    multiline: { type: Boolean, default: false },
    expandable: { type: Boolean, default: false },
  },

  setup(props) {
    const rootClasses = computed(() => ({
      UiCell: true,
      "UiCell--multiline": props.multiline,
    }));

    return {
      props,
      rootClasses,
    };
  },
});
