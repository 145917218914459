
import "./UiTabbarItem.css";

import { computed, defineComponent, PropType } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";

import { HeroIcons } from "../icon";

import UiIcon from "../icon/UiIcon.vue";
import UiTappable from "../tappable/UiTappable.vue";

export default defineComponent({
  components: { UiTappable, UiIcon },

  props: {
    icon: { type: String as PropType<HeroIcons> },
    title: { type: String },
    active: { type: Boolean, default: false },
  },

  setup(props) {
    const platform = usePlatform();

    const rootClasses = computed(() => [
      getClassName("UiTabbarItem", platform),
      { "UiTabbarItem--active": props.active },
    ]);

    return {
      props,
      rootClasses,
    };
  },
});
