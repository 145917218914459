import { Ref, ref } from "vue";
import { createGlobalState, useStorage } from "@vueuse/core";
import { TUserWithSchool } from "@/types/user";

interface State {
  user: Maybe<TUserWithSchool>;
  credential: UserCredential;
}

export const useGlobalStore = createGlobalState<Ref<State>>(() =>
  useStorage(
    "cache",
    { user: null, credential: { login: "", pwd: "" } },
    localStorage
  )
);

const authUser = ref<Maybe<TUserWithSchool>>();
const currentUser = ref<Maybe<TUserWithSchool>>();
const users = ref<TUserWithSchool[]>([]);

export function useStore() {
  function setAuthUser(user: Maybe<TUserWithSchool>) {
    authUser.value = user;
  }

  function setCurrentUser(user: Maybe<TUserWithSchool>) {
    currentUser.value = user;
  }

  function setUsers(payload: TUserWithSchool[]) {
    users.value = payload;
  }

  return {
    authUser,
    setAuthUser,

    currentUser,
    setCurrentUser,

    users,
    setUsers,
  };
}
