import { createRouter, createWebHashHistory } from "vue-router";

import { HeroIcons } from "@/components/ui/types";
import { TPermission } from "@/types/permission";

import routes from "./routes";
// import { usePermissions } from "@/use/usePermissions";

declare module "vue-router" {
  interface RouteMeta {
    hidden?: boolean;
    title?: string;
    allowFor?: TPermission | TPermission[];
    priority?: number;
    icon?: HeroIcons;
    group?: string;
  }
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...routes,

    {
      path: "/more",
      name: "more",
      component: () => import("@/views/More.vue"),
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
    },

    {
      path: "/forbidden",
      name: "forbidden",
      component: () => import("@/views/403.vue"),
    },
  ],

  scrollBehavior: (to, from) => {
    if (to.path === from.path) {
      return { top: 0, behavior: "smooth" };
    } else {
      return { top: 0 };
    }
  },
});

// router.beforeEach((to, from, next) => {
//   const { isAllow } = usePermissions();

//   to.meta?.allowFor
//     ? isAllow(to.meta.allowFor)
//       ? next()
//       : next({ path: "/forbidden" })
//     : next();
// });

export default router;
