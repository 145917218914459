import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3eea9920"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "field" }
const _hoisted_2 = { class: "input-field" }
const _hoisted_3 = ["type", "value", "readonly", "disabled"]
const _hoisted_4 = {
  key: 1,
  class: "input-field__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("p", {
            key: 0,
            class: _normalizeClass(["input-field__label", {
          'input-field__label--focused': _ctx.focus || _ctx.focused,
          'input-field__label--not-empty': _ctx.modelValue && _ctx.modelValue.length,
        }])
          }, _toDisplayString(_ctx.label), 3))
        : _createCommentVNode("", true),
      _createElementVNode("input", _mergeProps(_ctx.$attrs, {
        onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onFocus && _ctx.onFocus(...args))),
        onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
        onInput: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args))),
        type: _ctx.type,
        value: _ctx.modelValue,
        readonly: _ctx.readonly,
        disabled: _ctx.disabled,
        class: [{
          'input-field__input--focused': _ctx.focused,
        }, "input-field__input"]
      }), null, 16, _hoisted_3),
      (_ctx.error && !_ctx.pristine)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.error), 1))
        : _createCommentVNode("", true)
    ])
  ]))
}