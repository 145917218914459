import { computed } from "vue";

import { TAuthCredential } from "@/types/auth";

import { useStore } from "@/use/useStore";
import usersService from "@/services/users.service";

export function useUsers() {
  const store = useStore();

  const users = computed(() => store.users);

  async function getUsers() {
    const users = await usersService.fetchUsers();
    store.setUsers(users);
  }

  async function touchAccount(credential: TAuthCredential) {
    await usersService.touchUser(credential);
    await getUsers();
  }

  async function detachAccount(id: number) {
    await usersService.detachUser(id);

    const index = store.users.value.findIndex((u) => u.id === id);
    store.users.value.splice(index, 1);
  }

  return {
    users,
    getUsers,
    touchAccount,
    detachAccount,
  };
}
