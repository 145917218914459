const touchEnabled = (): boolean =>
  window !== undefined && "ontouchstart" in window;

const coordX = (e: TouchEvent): number => {
  if (e.touches[0].clientX != null) {
    return e.touches[0].clientX;
  }
  return e.changedTouches && e.changedTouches[0].clientX;
};

/*
 * Получает координату по оси ординат из touch- или mouse-события
 */
const coordY = (e: TouchEvent): number => {
  if (e.touches[0].clientY != null) {
    return e.touches[0].clientY;
  }
  return e.changedTouches && e.changedTouches[0].clientY;
};

export { touchEnabled, coordX, coordY };
