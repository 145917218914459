import Message from "./Message";
import { MessageInstance, MessageOptions } from "./types";

export function useMessage(): {
  (options: MessageOptions): MessageInstance;
  close(id: number, onClose?: (() => void) | undefined): void;
  primary(options: Omit<MessageOptions, "type">): MessageInstance;
  danger(options: Omit<MessageOptions, "type">): MessageInstance;
  success(options: Omit<MessageOptions, "type">): MessageInstance;
  warning(options: Omit<MessageOptions, "type">): MessageInstance;
} {
  return Message;
}
