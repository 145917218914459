
import "./UiTitle.css";

import { computed, defineComponent, PropType } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";

type TitleLevel = "1" | "2" | "3";
type TitleWeight = "heavy" | "bold" | "semibold" | "medium" | "regular";

export default defineComponent({
  inheritAttrs: false,

  props: {
    level: { type: String as PropType<TitleLevel>, default: "1" },
    weight: { type: String as PropType<TitleWeight>, default: "regular" },
    tag: { type: String },
  },

  setup(props) {
    const platform = usePlatform();

    const component = computed(() =>
      props.tag ? props.tag : "h" + props.level
    );

    const rootClasses = computed(() => [
      getClassName("UiTitle", platform),
      `UiTitle--w-${props.weight}`,
      `UiTitle--l-${props.level}`,
      {},
    ]);

    return {
      component,
      rootClasses,
    };
  },
});
