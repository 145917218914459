interface ScrollContext {
  scrollElement: HTMLElement | null;
  scrollAnimationDuration: number;
  getScrollPosition: (currentPosition: number) => number;
  onScrollToRightBorder: () => void;
  onScrollEnd: () => void;
  onScrollStart: () => void;
  /**
   * Начальная ширина прокрутки.
   * В некоторых случаях может отличаться от текущей ширины прокрутки из-за transforms: translate
   */
  initialScrollWidth: number;
}

/**
 * Код анимации скрола, на основе полифила: https://github.com/iamdustan/smoothscroll
 * Константа взята из полифила (468), эксперементально получили 250
 */
const SCROLL_ONE_FRAME_TIME = 250;

function useHorizontalScroll({
  scrollElement,
  getScrollPosition,
  onScrollToRightBorder,
  onScrollEnd,
  onScrollStart,
  initialScrollWidth,
  scrollAnimationDuration = SCROLL_ONE_FRAME_TIME,
}: ScrollContext) {
  if (!scrollElement || !getScrollPosition) return;

  // Максимальное значение сдвига влево
  const maxLeft = initialScrollWidth - scrollElement.offsetWidth;
  const startLeft = scrollElement.scrollLeft;

  let endLeft = getScrollPosition(startLeft);

  onScrollStart();

  if (endLeft >= maxLeft) {
    onScrollToRightBorder();
    endLeft = maxLeft;
  }

  const startTime = Date.now();

  function scroll() {
    if (!scrollElement) {
      onScrollEnd();
      return;
    }

    const time = Date.now();
    const elapsed = Math.min((time - startTime) / scrollAnimationDuration, 1);

    const value = 0.5 * (1 - Math.cos(Math.PI * elapsed));

    const currentLeft = startLeft + (endLeft - startLeft) * value;
    scrollElement.scrollLeft = Math.ceil(currentLeft);

    if (scrollElement.scrollLeft !== Math.max(0, endLeft)) {
      requestAnimationFrame(scroll);
      return;
    }

    onScrollEnd();
  }

  scroll();
}

export default useHorizontalScroll;
