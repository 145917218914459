import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: "UiTappable__wave",
    style: _normalizeStyle({
      top: _ctx.y + 'px',
      left: _ctx.x + 'px',
      width: _ctx.size + 'px',
      height: _ctx.size + 'px',
    })
  }, null, 4))
}