import { ref } from "vue";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";
import { QueryParams } from "@/api/httpClient";

const news = ref<Post[]>([]);

export function useNews() {
  const { currentUser } = useStore();

  async function fetchNews(params?: QueryParams) {
    const { data } = await Api.fetchNews(currentUser.value!.id, params);
    news.value = data;
  }

  async function votePost(postId: number, variants: number[]) {
    const { data } = await Api.votePost(
      currentUser.value!.id,
      postId,
      variants
    );

    const post = news.value.find((post) => post.id == data.id);

    if (post) {
      post.voting = data.voting;
    }
  }

  return {
    news,
    fetchNews,
    votePost,
  };
}
