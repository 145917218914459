import { InjectionKey, Ref } from "vue";

export const AppbarHeightKey: InjectionKey<Ref<number>> =
  Symbol("AppbarHeightKey");

export const TabbarHeightKey: InjectionKey<Ref<number>> =
  Symbol("TabbarHeightKey");

export const AppScrollKey: InjectionKey<{ x: number; y: number }> =
  Symbol("AppScrollKey");
