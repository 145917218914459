import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "UiTabbarItem__in" }
const _hoisted_2 = {
  key: 0,
  class: "UiTabbarItem__icon"
}
const _hoisted_3 = { class: "UiTabbarItem__content" }
const _hoisted_4 = { class: "UiTabbarItem__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiTappable = _resolveComponent("UiTappable")!

  return (_openBlock(), _createBlock(_component_UiTappable, {
    class: _normalizeClass(_ctx.rootClasses)
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.icon || _ctx.$slots.icon)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _renderSlot(_ctx.$slots, "icon", {}, () => [
                (_ctx.icon)
                  ? (_openBlock(), _createBlock(_component_UiIcon, {
                      key: 0,
                      icon: _ctx.icon
                    }, null, 8, ["icon"]))
                  : _createCommentVNode("", true)
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1)
          ])
        ])
      ])
    ]),
    _: 3
  }, 8, ["class"]))
}