
import "./UiCaption.css";

import { computed, defineComponent, PropType } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";

type CaptionWeight = "regular" | "medium" | "semibold" | "bold";
type CaptionLevel = "1" | "2" | "3" | "4";

export default defineComponent({
  inheritAttrs: false,

  props: {
    weight: { type: String as PropType<CaptionWeight>, default: "regular" },
    level: { type: String as PropType<CaptionLevel>, default: "1" },
    tag: { type: String, default: "span" },
    caps: { type: Boolean, default: false },
  },

  setup(props) {
    const platform = usePlatform();

    const rootClasses = computed(() => [
      getClassName("UiCaption", platform),
      `UiCaption--w-${props.weight}`,
      `UiCaption--l-${props.level}`,
      {
        "UiCaption--caps": props.caps,
      },
    ]);

    return {
      props,
      rootClasses,
    };
  },
});
