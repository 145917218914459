import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "UiCell__left"
}
const _hoisted_2 = { class: "UiCell__main" }
const _hoisted_3 = { class: "UiCell__content" }
const _hoisted_4 = { class: "UiCell__slot" }
const _hoisted_5 = {
  key: 0,
  class: "UiCell__title"
}
const _hoisted_6 = {
  key: 1,
  class: "UiCell__description"
}
const _hoisted_7 = {
  key: 1,
  class: "UiCell__right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiTappable = _resolveComponent("UiTappable")!

  return (_openBlock(), _createBlock(_component_UiTappable, _mergeProps(_ctx.$attrs, { class: _ctx.rootClasses }), {
    default: _withCtx(() => [
      (_ctx.$slots.left)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "left")
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "default", {}, () => [
              (_ctx.title)
                ? (_openBlock(), _createElementBlock("h3", _hoisted_5, _toDisplayString(_ctx.title), 1))
                : _createCommentVNode("", true),
              (_ctx.description)
                ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.description), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      (_ctx.$slots.right || _ctx.expandable)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _renderSlot(_ctx.$slots, "right"),
            (_ctx.expandable)
              ? (_openBlock(), _createBlock(_component_UiIcon, {
                  key: 0,
                  size: 16,
                  type: "solid",
                  icon: "ChevronRight",
                  class: "UiCell__expandable"
                }))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["class"]))
}