import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4539de3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "message__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "fade-bottom",
    onAfterLeave: _ctx.destroy
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        style: _normalizeStyle({ top: `${_ctx.top}px` }),
        class: _normalizeClass(["message", [`message--${_ctx.type}`]])
      }, [
        (_ctx.type)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icons[_ctx.type]), {
              key: 0,
              class: "message__icon"
            }))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
      ], 6), [
        [_vShow, _ctx.show]
      ])
    ]),
    _: 1
  }, 8, ["onAfterLeave"]))
}