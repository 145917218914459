
import "./UiAppbarButton.css";

import { computed, defineComponent } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";

import UiTappable from "../tappable/UiTappable.vue";

export default defineComponent({
  components: { UiTappable },

  setup(props, { attrs }) {
    const platform = usePlatform();

    const tag = computed(() => (attrs.href ? "a" : "button"));

    const rootClasses = computed(() => [
      getClassName("UiAppbarButton", platform),
      {},
    ]);

    return {
      props,
      tag,
      rootClasses,
    };
  },
});
