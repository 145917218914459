import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "UiBtn__loader"
}
const _hoisted_2 = { class: "UiBtn__in" }
const _hoisted_3 = {
  key: 0,
  class: "UiBtn__left"
}
const _hoisted_4 = {
  key: 1,
  class: "UiBtn__content"
}
const _hoisted_5 = {
  key: 2,
  class: "UiBtn__right"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiLoader = _resolveComponent("UiLoader")!
  const _component_UiTappable = _resolveComponent("UiTappable")!

  return (_openBlock(), _createBlock(_component_UiTappable, _mergeProps(_ctx.$attrs, {
    onClick: _ctx.onClick,
    class: _ctx.rootClass,
    disabled: _ctx.disabled,
    tag: "button"
  }), {
    default: _withCtx(() => [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _createVNode(_component_UiLoader)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("span", _hoisted_2, [
        (_ctx.$slots.left)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _renderSlot(_ctx.$slots, "left")
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.default)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
              _renderSlot(_ctx.$slots, "default")
            ]))
          : _createCommentVNode("", true),
        (_ctx.$slots.right)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
              _renderSlot(_ctx.$slots, "right")
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 16, ["onClick", "class", "disabled"]))
}