
import { defineComponent, RenderFunction } from "vue";
import {
  ExclamationIcon,
  XCircleIcon,
  CheckCircleIcon,
  InformationCircleIcon,
} from "@heroicons/vue/solid";

export default defineComponent({
  data() {
    return {
      id: null,
      show: false,
      text: "",
      icon: "",
      duration: 3000,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose: () => {},
      top: 6,
      type: "default",

      icons: {
        primary: InformationCircleIcon,
        success: CheckCircleIcon,
        danger: XCircleIcon,
        warning: ExclamationIcon,
      } as Record<string, RenderFunction>,
    };
  },

  mounted() {
    if (this.duration > 0) {
      setTimeout(() => {
        this.show = false;
        this.onClose?.();
      }, this.duration);
    }
  },

  methods: {
    destroy() {
      this.$el.parentNode?.removeChild(this.$el);
    },
  },
});
