import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _mergeProps(_ctx.$attrs, { class: _ctx.rootClasses }), [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.CounterTypography.component), _mergeProps(_ctx.CounterTypography.props, { class: "UiCounter__in" }), {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _: 3
    }, 16))
  ], 16))
}