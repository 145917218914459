import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

import "./registerServiceWorker";

import "@/assets/animations.css";
import "windi.css";

import { defineBreakpoints } from "./globalVariables";

declare module "vue-router" {
  interface RouteMeta {
    title?: string;
    page?: "default" | "simple";
  }
}

const app = createApp(App);

app.use(router);

// define global variables
defineBreakpoints(app);

app.mount("#app");
