
import "./UiBtn.css";

import { computed, defineComponent } from "vue";

import UiTappable from "../tappable/UiTappable.vue";
import UiLoader from "../loader/UiLoader.vue";

export default defineComponent({
  inheritAttrs: false,

  components: { UiTappable, UiLoader },

  // TODO Create rich typization
  props: {
    block: { type: Boolean, default: false },
    variant: { type: String, default: "primary" },
    size: { type: String, default: "md" },
    loading: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
  },

  emits: ["click"],

  setup(props, { emit }) {
    const rootClass = computed(() => ({
      UiBtn: true,
      "UiBtn--block": props.block,
      [`UiBtn--${props.variant}`]: props.variant,
      [`UiBtn--${props.size}`]: props.size,
    }));

    function onClick(e: MouseEvent | TouchEvent) {
      if (props.loading) {
        return;
      }

      emit("click", e);
    }

    return {
      props,
      rootClass,
      onClick,
    };
  },
});
