import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "UiAppbarButton__left"
}
const _hoisted_2 = {
  key: 1,
  class: "UiAppbarButton__right"
}
const _hoisted_3 = {
  key: 2,
  class: "UiAppbar__counter"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiTappable = _resolveComponent("UiTappable")!

  return (_openBlock(), _createBlock(_component_UiTappable, _mergeProps(_ctx.$attrs, {
    tag: _ctx.tag,
    class: _ctx.rootClasses
  }), {
    default: _withCtx(() => [
      (_ctx.$slots.left)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
            _renderSlot(_ctx.$slots, "left")
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default"),
      (_ctx.$slots.right)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _renderSlot(_ctx.$slots, "right")
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots.counter)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
            _renderSlot(_ctx.$slots, "counter")
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 16, ["tag", "class"]))
}