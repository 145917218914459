import { ref, Ref } from "vue";

export interface AsyncHook {
  isPending: Ref<boolean>;
  isFailure: Ref<boolean>;
  isSuccess: Ref<boolean>;
  error: Ref<unknown | undefined>;
  execute: (...args: unknown[]) => Promise<void>;
}

export function useAsync<T>(
  promise: Promise<T> | ((...args: any[]) => Promise<T>),
  immediate = false
): AsyncHook {
  const isPending = ref<boolean>(false);
  const isFailure = ref<boolean>(false);
  const isSuccess = ref<boolean>(false);
  const error = ref<unknown | undefined>(undefined);

  async function execute(...args: any[]) {
    isPending.value = true;
    isFailure.value = false;
    isSuccess.value = false;

    try {
      const _promise =
        typeof promise === "function" ? promise(...args) : promise;

      await _promise;

      isSuccess.value = true;
    } catch (e) {
      isFailure.value = true;
      error.value = e;
      throw e;
    } finally {
      isPending.value = false;
    }
  }

  if (immediate) {
    execute();
  }

  return { isPending, isSuccess, isFailure, error, execute };
}
