export function formatCurrency(currency: number): string {
  return new Intl.NumberFormat("ru-RU", {
    style: "currency",
    currency: "RUB",
  }).format(currency);
}

export function formatDate(
  date?: string | Date | number,
  options?: Intl.DateTimeFormatOptions
): string {
  date = date ? date : Date.now();
  return new Date(date).toLocaleString("ru-RU", options);
}

export function declOfNum(number = 0, titles: string[]): string {
  const cases = [2, 0, 1, 1, 1, 2];

  const n = Math.abs(number);

  return titles[
    n % 100 > 4 && n % 100 < 20 ? 2 : cases[n % 10 < 5 ? n % 10 : 5]
  ];
}

export function formatUnit(
  value: string | number | undefined,
  unit = "px"
): string {
  return typeof value === "string" ? value : value + unit;
}

export function isIos(): boolean {
  return (
    (/iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    !window.MSStream
  );
}

export function isInStandaloneMode(): boolean {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  return "standalone" in window.navigator && window.navigator.standalone;
}

export function isCreditCardValid(pan: string): boolean {
  if (/[^0-9-\s]+/.test(pan)) return false;

  let nCheck = 0;
  let bEven = false;
  pan = pan.replace(/\D/g, "");

  for (let n = pan.length - 1; n >= 0; n--) {
    const cDigit = pan.charAt(n);
    let nDigit = parseInt(cDigit, 10);

    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;

    nCheck += nDigit;
    bEven = !bEven;
  }

  return nCheck % 10 == 0;
}
