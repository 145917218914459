
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  props: {
    modelValue: Boolean,
  },

  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const checked = ref(!!props.modelValue);

    watch(
      () => props.modelValue,
      (value) => {
        checked.value = !!value;
      }
    );

    const classes = computed(() => ({
      "ui-switch": true,
      "ui-switch--checked": checked.value,
    }));

    function handleChange(e: Event) {
      const value = (e.target as HTMLInputElement).checked;
      checked.value = value;
      emit("update:modelValue", value);
    }

    return {
      checked,
      classes,
      handleChange,
    };
  },
});
