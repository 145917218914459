
import "./UiAppbar.css";

import { computed, defineComponent, inject, reactive, ref } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";
import { Platform } from "@/lib/platform";

import { AppbarHeightKey, AppScrollKey } from "../../injections";

export default defineComponent({
  props: {
    title: { type: String },
    temporary: { type: Boolean, default: false },
  },

  setup(props, { slots }) {
    const height = inject(AppbarHeightKey, ref(0));
    const scroll = inject(AppScrollKey, reactive({ x: 0, y: 0 }));

    const platform = usePlatform();

    height.value = platform === Platform.IOS ? 44 : 56;

    const showLeft = computed(() => platform === Platform.IOS);

    const rootClasses = computed(() => [
      getClassName("UiAppbar", platform),
      {
        "UiAppbar--temporary": props.temporary,
        "UiAppbar--temporary-visible":
          props.temporary && scroll.y > height.value,
      },
    ]);

    return {
      props,
      showLeft,
      rootClasses,
    };
  },
});
