import { computed } from "vue";
import axios, { CancelToken } from "axios";
import { useStorage } from "@vueuse/core";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";

let date = Date.now();

const qr = useStorage<{ qr: string; countIndex: number }[]>(
  "qr",
  [],
  localStorage
);
const qrIndex = useStorage<number>("qrIndex", -1, localStorage);

export function useQr() {
  let cancelToken: CancelToken;

  const { currentUser } = useStore();

  const currentQr = computed(
    () => qr.value.find((qr) => qr.countIndex === qrIndex.value)?.qr
  );

  async function counterIndex() {
    const { data } = await Api.fetchIndex(currentUser.value!.id, cancelToken);
    qrIndex.value = data;
  }

  async function fetchQr() {
    date = Date.now();
    const index = qr.value.findIndex((qr) => qr.countIndex === qrIndex.value);

    if (index > 0 && index + 1 < qr.value.length) {
      qrIndex.value = qr.value[index + 1].countIndex;
    }

    const response = await Api.fetchQrCodes(currentUser.value!.id, date, {
      index: `${qrIndex.value - 1}`,
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (date === response.timestamp) {
      qr.value = response.data;

      if (response.data.length) {
        qrIndex.value = response.data[1].countIndex;
      }
    }
  }

  function cancelIndex() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    cancelToken = axios.CancelToken.source();
  }

  return {
    qr: currentQr,
    qrIndex,
    fetchQr,
    cancelIndex,
    counterIndex,
  };
}
