import { computed } from "vue";

import { ISchoolMenuFood } from "@/types/school-menu";
import { useStorage } from "@vueuse/core";

type TCartItem = ISchoolMenuFood & { count: number };

const cart = useStorage<Record<string, TCartItem>>("cart", {}, sessionStorage);

export function useCart() {
  const sum = computed(() =>
    Object.values(cart.value).reduce(
      (acc, value) => acc + value.price * value.count,
      0
    )
  );
  const size = computed(() =>
    Object.values(cart.value).reduce((acc, value) => acc + value.count, 0)
  );

  function addToCart(food: ISchoolMenuFood) {
    cart.value[food.id]
      ? cart.value[food.id].count++
      : (cart.value[food.id] = { ...food, count: 1 });
  }

  function removeFromCart(food: ISchoolMenuFood) {
    cart.value[food.id] && cart.value[food.id].count > 1
      ? cart.value[food.id].count--
      : delete cart.value[food.id];
  }

  function clearCart() {
    cart.value = {};
  }

  return {
    cart,
    sum,
    size,
    addToCart,
    removeFromCart,
    clearCart,
  };
}
