import { createApp } from "vue";

import MessageContainer from "./MessageContainer.vue";
import { MessageInstance, MessageOptions } from "./types";

const messages: MessageInstance[] = [];

let idStart = 0;

const Message = (options: MessageOptions): MessageInstance => {
  const onClose = options.onClose;
  const id = idStart++;

  options.onClose = () => {
    Message.close(id, onClose);
  };

  const tempDiv = document.createElement("div");
  const instance = createApp(MessageContainer).mount(
    tempDiv
  ) as MessageInstance;

  const newOptions = Object.assign(options, { id });

  instance.$data.id = newOptions.id;
  instance.$data.duration = newOptions.duration;
  instance.$data.text = newOptions.text;
  instance.$data.type = newOptions.type;
  instance.$data.onClose = newOptions.onClose;

  document.body.appendChild(instance.$el);
  instance.$data.show = true;

  let topDist = 0;

  messages.forEach((el) => {
    topDist += (el.$el as HTMLElement).offsetHeight + 8;
  });

  instance.$data.top = topDist + 8;
  messages.push(instance);

  return instance;
};

Message.close = (id: number, onClose?: () => void) => {
  const index = messages.findIndex((el) => el.$data.id === id);
  const height = (messages[index].$el as HTMLElement).offsetHeight;

  messages.splice(index, 1);

  onClose?.();

  messages.forEach((el) => {
    if (el.$data.id > id) {
      el.top -= height + 8;
    }
  });
};

Message.primary = (options: Omit<MessageOptions, "type">) =>
  Message({ type: "primary", ...options });

Message.danger = (options: Omit<MessageOptions, "type">) =>
  Message({ type: "danger", ...options });

Message.success = (options: Omit<MessageOptions, "type">) =>
  Message({ type: "success", ...options });

Message.warning = (options: Omit<MessageOptions, "type">) =>
  Message({ type: "warning", ...options });

export default Message;
