import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ml-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UiIcon = _resolveComponent("UiIcon")!
  const _component_UiAppbarButton = _resolveComponent("UiAppbarButton")!

  return (_openBlock(), _createBlock(_component_UiAppbarButton, {
    style: _normalizeStyle({ marginLeft: _ctx.title && '-8px' })
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UiIcon, { icon: _ctx.icon }, null, 8, ["icon"]),
      (_ctx.title)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.title), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["style"]))
}