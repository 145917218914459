
import "./UiCounter.css";

import { computed, defineComponent, PropType } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";

import UiCaption from "../typography/UiCaption.vue";
import UiText from "../typography/UiText.vue";

type CounterVariant = "primary" | "secondary" | "prominent";
type CounterSize = "s" | "m";

export default defineComponent({
  inheritAttrs: false,

  props: {
    size: { type: String as PropType<CounterSize>, default: "m" },
    variant: { type: String as PropType<CounterVariant>, default: "secondary" },
  },

  setup(props) {
    const platform = usePlatform();

    const rootClasses = computed(() => [
      getClassName("UiCounter", platform),
      `UiCounter--${props.variant}`,
      `UiCounter--s-${props.size}`,
      {},
    ]);

    const CounterTypography = computed(() => {
      return props.size === "s"
        ? { component: UiCaption, props: { level: "2", weight: "regular" } }
        : { component: UiText, props: { weight: "medium" } };
    });

    return {
      props,
      rootClasses,
      CounterTypography,
    };
  },
});
