import UAParser from "ua-parser-js";
import { computed, ref } from "vue";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";
import { formatDate } from "@/utils";

export function useSessions() {
  const { currentUser } = useStore();

  const lkSession = ref<LkSession | null>(null);

  const lkSessionDate = computed(() => {
    return lkSession.value
      ? formatDate(lkSession.value.time_create, {
          day: "2-digit",
          month: "long",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        })
      : null;
  });

  const lkUserAgent = computed(() => {
    return lkSession?.value
      ? new UAParser(lkSession.value.user_agent).getResult()
      : null;
  });

  const lkUserAgentOs = computed(() => {
    const o = lkUserAgent.value?.os;
    return o ? [o.name, o.version].filter(Boolean).join(" ") : "-";
  });

  const lkUserAgentBrowser = computed(() => {
    const o = lkUserAgent.value?.browser;
    return o ? [o.name, o.version].filter(Boolean).join(" ") : "-";
  });

  const lkUserAgentFormatted = computed(() => {
    return {
      os: lkUserAgentOs.value,
      browser: lkUserAgentBrowser.value,
      description: [lkUserAgentBrowser.value, lkSessionDate.value]
        .filter(Boolean)
        .join("\n"),
    };
  });

  async function fetchLkSession() {
    const { data } = await Api.fetchSession(currentUser.value!.id);
    lkSession.value = data;
  }

  async function deleteLkSession() {
    const { data } = await Api.deleteSession(currentUser.value!.id);
    lkSession.value = null;
    fetchLkSession();
    return data;
  }

  return {
    lkSession,
    lkUserAgent,
    lkUserAgentOs,
    lkUserAgentBrowser,
    lkUserAgentFormatted,
    fetchLkSession,
    deleteLkSession,
  };
}
