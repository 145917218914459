
import "./UiHeader.css";

import { computed, defineComponent, PropType } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";
import { Platform } from "@/lib/platform";

import UiTitle from "../typography/UiTitle.vue";
import UiHeadline from "../typography/UiHeadline.vue";
import UiSubhead from "../typography/UiSubhead.vue";
import UiCaption from "../typography/UiCaption.vue";

type HeaderMode = "primary" | "secondary" | "tertiary";

export default defineComponent({
  inheritAttrs: false,

  props: {
    mode: { type: String as PropType<HeaderMode>, default: "primary" },
    title: { type: [Number, String] },
    description: { type: String },
    tag: { type: String, default: "div" },
    multiline: { type: Boolean, default: false },
  },

  setup(props) {
    const platform = usePlatform();

    const rootClasses = computed(() => [
      getClassName("UiHeader", platform),
      `UiHeader--mode-${props.mode}`,
      {
        UiHeader: true,
        "UiHeader--multiline": props.multiline,
      },
    ]);

    const HeaderContent = computed(() => {
      if (platform === Platform.IOS) {
        switch (props.mode) {
          case "primary":
          case "tertiary":
            return {
              component: UiTitle,
              props: { weight: "semibold", level: "3" },
            };
          case "secondary":
            return {
              component: UiCaption,
              props: { caps: true, weight: "semibold", level: "1" },
            };
        }
      }

      switch (props.mode) {
        case "primary":
          return { component: UiHeadline, props: { weight: "medium" } };
        case "tertiary":
        case "secondary":
          return {
            component: UiCaption,
            props: { caps: true, weight: "medium", level: "1" },
          };

        default:
          return { component: "h3" };
      }
    });

    const SubtitleContent = computed(() => {
      return props.mode === "secondary"
        ? { component: UiSubhead, props: { weight: "regular" } }
        : { component: UiCaption, props: { level: "1", weight: "regular" } };
    });

    return {
      props,
      rootClasses,
      HeaderContent,
      SubtitleContent,
    };
  },
});
