
import { computed, defineComponent } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { Platform } from "@/lib/platform";

import UiAppbarButton from "./UiAppbarButton.vue";
import UiIcon from "../icon/UiIcon.vue";

export default defineComponent({
  components: { UiAppbarButton, UiIcon },

  setup() {
    const platform = usePlatform();

    const title = computed(() => platform === Platform.IOS && "Назад");
    const icon = computed(() =>
      platform === Platform.IOS ? "ChevronLeft" : "ArrowLeft"
    );

    return {
      title,
      icon,
    };
  },
});
