import { App, reactive } from "vue";
import { breakpointsTailwind, useBreakpoints } from "@vueuse/core";

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $breakpoints: Record<"xs" | "sm" | "md" | "lg" | "xl" | "xxl", boolean>;
  }
}

export function defineBreakpoints(Vue: App): void {
  const breakpoints = useBreakpoints(breakpointsTailwind);

  Vue.config.globalProperties.$breakpoints = reactive({
    xs: breakpoints.smaller("sm"),
    sm: breakpoints.greater("sm"),
    md: breakpoints.greater("md"),
    lg: breakpoints.greater("lg"),
    xl: breakpoints.greater("xl"),
    xxl: breakpoints.greater("2xl"),
  });
}
