<script setup lang="ts"></script>

<template>
  <div class="ui-modal-page-header">
    <div class="ui-modal-page-header__in">
      <div v-if="$slots.before" class="ui-modal-page-header__before">
        <slot name="before" />
      </div>

      <div class="ui-modal-page-header__content">
        <slot />
      </div>

      <div v-if="$slots.after" class="ui-modal-page-header__after">
        <slot name="after" />
      </div>
    </div>
  </div>
</template>

<style>
.ui-modal-page-header {
  background: #ffffff;
}

.ui-modal-page-header__in {
  display: flex;
  align-items: center;
  height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  min-width: 0;
}

.ui-modal-page-header__content {
  font-size: 20px;
  font-weight: 700;
  flex-grow: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.ui-modal-page-header__before {
  flex-shrink: 0;
  margin-right: 24px;
  color: #2688eb;
  display: flex;
  align-items: center;
}
.ui-modal-page-header__before > .ui-icon-btn:first-child {
  margin-left: -8px;
}

.ui-modal-page-header__after {
  flex-shrink: 0;
  margin-left: 24px;
  color: #2688eb;
  display: flex;
  align-items: center;
}
</style>
