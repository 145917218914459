export const codes: Record<number, string> = {
  0: "Питание",
  1: "Коллективное питание",
  2: "Самостоятельный заказ",
  3: "Покупка активной карты",
  4: "Коллективное питание (восстановленное)",
  5: "Личный кабинет",
  6: "Привязка карты",
  7: "Восстановление карты",
  8: "Активация карты",
  9: "Покупка неактивной карты",
  11: "Школьные сервисы",
  12: "Перенос средств",
  13: "Социальный счет",
  14: "Остаток социального счета",
  15: "Сух. паек",
  20: "Сбербанк",
  21: "Терминал",
  200: "Сбербанк",
  203: "Покупка активной карты",
  205: "Личный кабинет",
  207: "Восстановление карты",
  208: "Активация карты",
};

export const types: Record<number, string> = {
  10: "Пополнение",
  20: "Списание",
};

export function getCodeName(code: number): string {
  return codes[code] || "Прочее";
}

export function getTypeName(type: number): string {
  return types[type] || "Прочее";
}

export const identifiersTypes: Record<number, string> = {
  0: "Карта",
  1: "Брелок",
  2: "Браслет",
  3: "NFC",
};

export const identifiersStates: Record<string, string> = {
  sync: "На синхронизации",
};

export function getIdentifierTypeName(type: number): string {
  return identifiersTypes[type];
}

export function getIdentifierState(state: string): string {
  return identifiersStates[state];
}
