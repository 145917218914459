import { ref } from "vue";

import * as Api from "@/api";
import { useStore } from "@/use/useStore";
import { QueryParams } from "@/api/httpClient";
import { IRecess } from "@/types/request";
import {
  IFoodset,
  ISchoolMenuFood,
  ISchoolMenuType,
  TSchoolMenuFormatted,
} from "@/types/school-menu";
import { groupBy, uniqBy, upperFirst } from "lodash";
import axios from "axios";

export function useRequests() {
  const statuses: Record<RequestStatus, string> = {
    accept: "Принят в столовой",
    cancel: "Отменен пользователем",
    eject: "Изъят по истечению времени",
    reject: "Отменен в столовой",
    success: "Завершен",
    wait: "В ожидании",
  };

  const store = useStore();

  const requests = ref<TRequest[]>([]);
  const recess = ref<IRecess[]>([]);
  const activeRequest = ref<Maybe<TRequest>>(null);
  const requestsMenu = ref<TSchoolMenuFormatted>(null);

  const getRequestStatus = (status: RequestStatus) => statuses[status];

  const getUniqFoods = (foods: ISchoolMenuFood[]) =>
    uniqBy(foods, (f) => [f.name, f.price, f.weight].join());

  const getVisibleFoods = (foods: ISchoolMenuFood[]) =>
    foods.filter((f) => f.visible);

  const getComplexPositions = (types: ISchoolMenuType[]) =>
    types
      .map((t) => t.foods)
      .flat()
      .filter((f) => f.setId > 0);

  const getFoodset = (sets: IFoodset[], id: number) =>
    sets.find((s) => s.id === id);

  async function fetchRequestsMenu() {
    const userId = store.currentUser.value!.id;

    try {
      const [{ data: menu }, { data: foodsets }] = await Promise.all([
        Api.fetchRequestsMenu(userId),
        Api.fetchFoodsets(userId),
      ]);

      /**
       * Убираем комплексы, их собирать будем самостоятельно.
       * Получаем только доступные для показа уникальные наименования.
       * Сортируем по приоритету.
       *  */
      const types = menu.types
        .filter((t) => t.name !== "Комплекс" && t.foods.length > 0)
        .map((t) => ({
          ...t,
          foods: getUniqFoods(getVisibleFoods(t.foods))
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((food) => ({ ...food, name: upperFirst(food.name) })),
        }))
        .sort((a, b) => b.priority - a.priority);

      /**
       * Собираем комплексы самостоятельно.
       * Название комплекса берем из списка всех доступных комплексов.
       * Подсчитываем суммарную стоимость комлекса исходя из его наименований.
       */
      const sets = Object.values(
        groupBy(getComplexPositions(menu.types), "setId")
      ).map((foods) => {
        const foodset = getFoodset(foodsets, foods[0].setId);

        return {
          foods,
          sum: foods.reduce((acc, food) => (acc += food.price), 0),
          name: upperFirst(foodset?.name || "Без названия"),
          description: foodset?.description || null,
        };
      });

      requestsMenu.value = { types, sets };
    } catch (e) {
      if (axios.isAxiosError(e) && e.response?.status === 404) {
        requestsMenu.value = null;
        return;
      }

      throw e;
    }
  }

  async function fetchRequests(query?: QueryParams) {
    const { data } = await Api.fetchRequests(
      store.currentUser.value!.id,
      query
    );
    requests.value = data.content;
  }

  async function fetchRequestsAvailable() {
    const { data } = await Api.fetchRequestAvailable(
      store.currentUser.value!.id
    );
  }

  async function deleteRequest(id: number) {
    await Api.deleteRequest(store.currentUser.value!.id, id);

    requests.value.find((r) => {
      if (r.id === id) {
        r.status = "cancel";
      }
    });
  }

  async function createRequest(request: CreateRequestData) {
    const { data } = await Api.createRequest(
      store.currentUser.value!.id,
      request
    );
    requests.value.unshift(data);
  }

  async function fetchRecess() {
    const { data } = await Api.fetchRecess(store.currentUser.value!.id);
    recess.value = data;
  }

  async function fetchActiveRequest() {
    const { data } = await Api.fetchRequests(store.currentUser.value!.id, {
      limit: "1",
    });

    if (
      data.content?.[0] &&
      ["accept", "wait"].includes(data.content?.[0].status)
    ) {
      activeRequest.value = data.content?.[0];
    } else {
      activeRequest.value = null;
    }
  }

  return {
    requests,
    fetchRequests,

    recess,
    fetchRecess,

    activeRequest,
    fetchActiveRequest,

    createRequest,
    deleteRequest,

    getRequestStatus,
    fetchRequestsAvailable,

    requestsMenu,
    fetchRequestsMenu,
  };
}
