
import "./UiGroup.css";

import { computed, defineComponent } from "vue";

import { usePlatform } from "@/hooks/usePlatform";
import { getClassName } from "@/lib/getClassName";

export default defineComponent({
  props: {
    flat: { type: Boolean, default: false },
  },

  setup(props) {
    const platform = usePlatform();

    const rootClasses = computed(() => [
      getClassName("UiGroup", platform),
      {
        "UiGroup--flat": props.flat,
      },
    ]);

    return {
      rootClasses,
    };
  },
});
