
import "./UiTappable.css";

import { computed, defineComponent, onMounted, onUnmounted, ref } from "vue";

import { shouldTriggerClickOnEnterOrSpace } from "../../lib/accessibility";
import { coordX, coordY } from "../../lib/touch";

import UiWave from "./UiWave.vue";

interface Wave {
  x: number;
  y: number;
  size: number;
  id: string;
}

export default defineComponent({
  inheritAttrs: false,

  components: { UiWave },

  // TODO Create rich typization
  props: {
    tag: { type: String },
    isHoverable: { type: Boolean, default: true },
    isActivable: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },

  emits: ["onKeyDown", "click"],

  setup(props, { attrs, emit }) {
    let delayAnimationTimer = 0;

    const containerRef = ref<HTMLElement>();
    const waves = ref<Wave[]>([]);

    const childHover = ref(false);

    const role = computed(() => (attrs.href || attrs.to ? "link" : "button"));
    const component = computed(
      () => props.tag || (attrs.href ? "a" : attrs.to ? "router-link" : "div")
    );
    const isCustomElement = computed(
      () => component.value !== "a" && component.value !== "button"
    );
    const hasHover = computed(() => props.isHoverable && !childHover.value);
    const hasActive = computed(
      () => props.isActivable && !childHover.value && !props.disabled
    );

    // Определение классов для компонента
    const rootClasses = computed(() => ({
      UiTappable: true,
      "UiTappable--with-hover": hasHover.value,
      "UiTappable--with-active": hasActive.value,
      disabled: props.disabled,
    }));

    // Работы с волнами на событие active
    function onStart(e: TouchEvent) {
      e.stopPropagation();

      if (hasActive.value) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const el = containerRef.value?.$el
          ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            containerRef.value?.$el
          : containerRef.value;

        const { top, left, width, height } = (
          el as HTMLElement
        ).getBoundingClientRect();
        const size = Math.max(width, height);
        const x = coordX(e) - left;
        const y = coordY(e) - top;

        waves.value.push({ id: Date.now().toString(), x, y, size });
      }
    }

    function removeWave(id: string) {
      const index = waves.value.findIndex((wave) => wave.id === id);
      waves.value.splice(index, 1);
    }

    function onChildEnter() {
      childHover.value = true;
    }

    function onChildLeave() {
      childHover.value = false;
    }

    /*
     * [a11y]
     * Обрабатывает событие onkeydown
     * для кастомных доступных элементов:
     * - role="link" (активация по Enter)
     * - role="button" (активация по Space и Enter)
     */
    function onKeyDown(e: KeyboardEvent) {
      if (isCustomElement.value && shouldTriggerClickOnEnterOrSpace(e)) {
        e.preventDefault();
        containerRef.value?.click();
      }

      emit("onKeyDown", e);
    }

    function onClick(e: MouseEvent) {
      const DELAY_ANIMATION = 70;

      clearTimeout(delayAnimationTimer);

      setTimeout(() => emit("click", e), DELAY_ANIMATION);
    }

    onMounted(() => {
      const child = containerRef.value?.children?.[0];

      if (child?.classList.contains("UiTappable")) {
        child?.addEventListener("mouseenter", onChildEnter);
        child?.addEventListener("mouseleave", onChildLeave);
      }
    });

    onUnmounted(() => {
      const child = containerRef.value?.children[0];

      if (child?.classList.contains("UiTappable")) {
        child?.removeEventListener("mouseenter", onChildEnter);
        child?.removeEventListener("mouseleave", onChildLeave);
      }
    });

    return {
      props,

      containerRef,
      waves,

      role,
      component,
      isCustomElement,
      hasHover,
      hasActive,

      rootClasses,

      onClick,
      onStart,
      removeWave,
      onKeyDown,
    };
  },
});
